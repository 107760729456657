/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.slim.min.js
 * - /npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js
 * - /npm/plyr@3.6.8/dist/plyr.min.js
 * - /npm/swiper@6.7.0/swiper-bundle.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
